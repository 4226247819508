;(function ($, window, document, undefined) {
    $(function () {

        // Allow "hover" on all bootstrap dropdown menus, this is
        // mostly useful for the navbar, but will work elsewhere too

        $.fn.unBindBootstrapNavbarCollapse = function() {

            return this.each(function() {

                var collapse = $(this).find('.navbar-collapse');

                var onMouseIn = function() {
                    $(this).addClass('open');
                };

                var onMouseOut = function() {
                    $(this).removeClass('open');
                };

                collapse.find('.dropdown').hover(onMouseIn, onMouseOut);

                collapse.on('hide.bs.collapse', function() {
                    $(this).find('.dropdown').bind('mouseenter', onMouseIn).bind('mouseleave', onMouseOut);
                }).on('show.bs.collapse', function() {
                    $(this).find('.dropdown').unbind('mouseenter').unbind('mouseleave');
                });

            });
        };

        $('.navbar').unBindBootstrapNavbarCollapse();

        var hash = window.location.hash.substr(1);

        if($('.panel-group .panel[data-id="' + hash + '"] .panel-collapse').length){

            var divPosition = $('.panel-group .panel[data-id="' + hash + '"]').offset();

            $('html, body').animate({scrollTop: divPosition.top - 45}, "slow");

            $('.panel-group .panel-collapse').collapse('hide');

            $('.panel-group .panel[data-id="' + hash + '"] .panel-collapse').collapse('show');

        }

       $('.panel-title a').append('<span class="pull-right plussign">+</span>');
       
       $('.panel-collapse').on('shown.bs.collapse', function() {
            var $panelbody = $(this),
                $panelheader = $panelbody.prev();

            $('html, body').animate({
                scrollTop: $panelheader.offset().top
            });

            $panelheader.find('span').text('-');
       }).on('hidden.bs.collapse', function() {
           $(this).prev().find('span').text('+');
       });





    });
})(jQuery, window, document);